import { render, staticRenderFns } from "./expertAdverseList.vue?vue&type=template&id=b84fa974&scoped=true"
import script from "./expertAdverseList.vue?vue&type=script&lang=js"
export * from "./expertAdverseList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b84fa974",
  null
  
)

export default component.exports